import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { Observable } from 'rxjs';
import { TurfApiService } from './turf.api.service';
import { StorageService } from '../storage.serviceV2';
import { webSocket } from 'rxjs/webSocket';
import { TurfBeaconService } from './turf-beacon/turf-beacon.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalSocketService {
  public requestOptions;
  public baseUrl;
  public dataValue: any;
  private socket;
  public host: any;
  public socketUrl: any;
  public facilityId;
  public organizationId: Number;

  constructor(
    private storageService: StorageService,
    private turfApiService: TurfApiService,
    private turfBeaconService: TurfBeaconService
  ) {
    // this.facility = this.storageService.get_beacon_facility();

    this.facilityId = this.storageService?.user?.currentFacilityId;
    this.organizationId = this.storageService?.user?.organizationId;

    this.socketUrl = this.turfApiService.config.baseUrl;
    this.socket = io(this.socketUrl, { transports: ['websocket'] });
    this.openWebSocket();
  }

  onTrackerSocket() {
    let context = this;
    let observable = new Observable<{ user: String; message: String }>(
      (observer) => {
        // Listen to the WebSocket for incoming data
        this.socket.on('TATrackerSession:' + context.facilityId, (data) => {
          // Emit the received data to the subscribers
          observer.next(data);
        });

        // Handle potential errors in the WebSocket connection
        this.socket.on('error', (error) => {
          // Notify subscribers about the error
          observer.error(error);
        });

        // Return the cleanup function
        return () => {
          // Disconnect the WebSocket when the Observable is unsubscribed
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }

  refreshEquipment() {
    let context = this;
    let observable = new Observable<{ user: String; message: String }>(
      (observer) => {
        // setTimeout(() => {
        this.facilityId = this.storageService?.user?.currentFacilityId;

        this.socket.on('Equipment:' + context.facilityId, (data) => {
          observer.next(data);
        });

        // Handle potential errors in the WebSocket connection
        this.socket.on('error', (error) => {
          // Notify subscribers about the error
          observer.error(error);
        });

        return () => {
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }

  beaconFacilityAssign() {
    let context = this;
    let observable = new Observable<{ user: String; message: String }>(
      (observer) => {
        this.socket.on('beaconFacilityAssign:' + context.facilityId, (data) => {
          observer.next(data);
        });
        // Handle potential errors in the WebSocket connection
        this.socket.on('error', (error) => {
          // Notify subscribers about the error
          observer.error(error);
        });
        return () => {
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }

  onBeaconActivation() {
    let context = this;
    let observable = new Observable<{ user: String; message: String }>(
      (observer) => {
        this.socket.on(
          'organization:beacon-activation:' + context.organizationId,
          (data) => {
            observer.next(data);
          }
        );
        // Handle potential errors in the WebSocket connection
        this.socket.on('error', (error) => {
          // Notify subscribers about the error
          observer.error(error);
        });
        return () => {
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }

  /**
   *
   * When new task is created by ignored the task
   */
  refreshFacilityTaskList() {
    let context = this;
    let observable = new Observable<{ user: String; message: String }>(
      (observer) => {
        this.facilityId = this.storageService?.user?.currentFacilityId;

        this.socket.on(`FacilityTaskList:${context.facilityId}`, (data) => {
          observer.next(data);
        });
        // Handle potential errors in the WebSocket connection
        this.socket.on('error', (error) => {
          // Notify subscribers about the error
          observer.error(error);
        });
        return () => {
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }

  /**
   *
   * When change the facility
   */
  refreshFacilityChange() {
    let context = this;
    let observable = new Observable<{ user: String; message: String }>(
      (observer) => {
        this.organizationId = this.storageService?.user?.organizationId;

        this.socket.on(
          `organization:FacilityChange:${context?.organizationId}`,
          (data) => {
            observer.next(data);
          }
        );
        // Handle potential errors in the WebSocket connection
        this.socket.on('error', (error) => {
          // Notify subscribers about the error
          observer.error(error);
        });
        return () => {
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }

  /**
   * When completion of auto clockout
   */

  autoClockoutCompleted() {
    const context = this;

    let observable = new Observable<{ user: String; message: String }>(
      (observer) => {
        this.organizationId = this.storageService?.user?.organizationId;
        this.socket.on(
          `organization:clockout:${context?.organizationId}`,
          (data) => observer.next()
        );
        // Handle potential errors in the WebSocket connection
        this.socket.on('error', (error) => {
          // Notify subscribers about the error
          observer.error(error);
        });
        return () => {
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }

  openWebSocket() {
    if (this.storageService?.user?.currentFacilityId) {
      this.turfBeaconService
        .getTrackerSession(this.storageService?.user?.currentFacilityId)
        .subscribe(
          (data) => {
            console.log('Request started');
          },
          (err) => {
            console.log('Error', err);
          }
        );
    }
  }


  copy_planner_completed_socket() {
    let context = this;
    let observable = new Observable<{ user: String; message: String }>(
      (observer) => {
        // setTimeout(() => {
        this.facilityId = this.storageService?.user?.currentFacilityId;

        this.socket.on('copyPlanner:' + context.facilityId, (data) => {
          observer.next(data);
        });

        // Handle potential errors in the WebSocket connection
        this.socket.on('error', (error) => {
          // Notify subscribers about the error
          observer.error(error);
        });

        return () => {
          this.socket.disconnect();
        };
      }
    );
    return observable;
  }

  refreshTaskWorkers() {}
}
